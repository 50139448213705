import React, { useState } from "react";
import { SEO } from "../components";
import Header from "../components/layout/header";
import Footer from "../components/layout/footer";
import FooterHeadline from "../components/layout/footerheadline";
import AboutUsMain from "../components/aboutus/about-us-main";
import Gallery from "../components/gallery/gallery-main";
import MailSection from "../components/aboutus/about-us-mail-section";
import classNames from "classnames";
import { Container } from "reactstrap";
import SideBar from "../components/layout/sidebar/sidebar";
import Topbar from "../components/layout/content/topbar";
import scrollTo from "gatsby-plugin-smoothscroll";
import { useInView } from "react-intersection-observer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { graphql } from "gatsby";

const Media = ({ data }) => {
  const seo = data.sanitySeo;
  const banner = data.sanityBanners.banner.asset.url;
  const [sidebarIsOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  const { ref, inView, entry } = useInView();

  const handleBrightness = () => {
    if (sidebarIsOpen) {
      return "brightness(50%)";
    } else {
      return "brightness(100%)";
    }
  };
  return (
    <div
      style={{
        maxWidth: "100vw",
        width: "100%",
        overflow: "hidden",
        boxSizing: "border-box",
      }}
    >
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ldSchema={seo.ldSchema}
      />
      <div className="App wrapper" id="top">
        <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
        <Container
          fluid
          className={classNames("p-0 m-0", { "is-open": sidebarIsOpen })}
          style={{
            filter: `${handleBrightness()}`,
          }}
        >
          <Header>
            {!sidebarIsOpen ? <Topbar toggleSidebar={toggleSidebar} /> : null}
          </Header>
          <div className="margin-class"></div>
          <AboutUsMain
            title="Gallery"
            bread="Gallery"
            buttontext=""
            src={banner}
            cover="100% 170%"
          />
          <div
            style={{
              backgroundColor: "#f7f7f7",
            }}
          >
            <Gallery />
          </div>
          <div ref={ref}>
            <MailSection />

            <Footer />
            <div
              style={{
                borderTop: "1px solid green",
              }}
            >
              <FooterHeadline />
            </div>
          </div>
        </Container>
      </div>
      {inView && (
        <div>
          <p
            className="back-to-top in scroll-up-button"
            onClick={() => {
              scrollTo("#top");
            }}
          >
            <FontAwesomeIcon
              className="scroll-up-button-icon mt-2"
              icon={faAngleUp}
            />
          </p>
        </div>
      )}
    </div>
  );
};
export default Media;

export const query = graphql`
  {
    sanityBanners(id: { eq: "-c3246175-6b68-5c2f-baf5-6ee01914143a" }) {
      id
      pageTitle
      banner {
        asset {
          url
        }
      }
    }
    sanitySeo(pagehandle: { eq: "gallery" }) {
      title
      description
      keywords
      ldSchema
    }
  }
`;
