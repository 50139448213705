import React from "react";
import Image from "../image";
const Gallery = () => {
  return (
    <div className="container-fluid mt-1 mb-1">
      <div>
        <div className="row">
          <div className="col-5 m-0 p-1">
            <Image src={`gallery-img-17.jpg`}></Image>
            <Image src={`gallery-img-18.jpg`}></Image>
            <Image src={`gallery-img-19.jpg`}></Image>
            <Image src={`gallery-img-20.jpg`}></Image>
            <Image src={`gallery-img-21.jpg`}></Image>
            <Image src={`gallery-img-22.jpg`}></Image>
            <Image src={`gallery-img-23.jpg`}></Image>
            <Image src={`gallery-img-24.jpg`}></Image>
            <Image src={`gallery-img-12.jpg`}></Image>
            <Image src={`gallery-img-28.jpg`}></Image>
          </div>
          <div className="col-5 m-0 p-0 ">
            <Image src={`gallery-img-31.jpg`}></Image>
            <Image src={`gallery-img-32.jpg`}></Image>
            <Image src={`gallery-img-33.jpg`}></Image>
            <Image src={`gallery-img-32.jpg`}></Image>
            <Image src={`gallery-img-33.jpg`}></Image>
            <Image src={`gallery-img-34.jpg`}></Image>
            <Image src={`gallery-img-35.jpg`}></Image>
            <Image src={`gallery-img-25.jpg`}></Image>
            <Image src={`gallery-img-26.jpg`}></Image>
          </div>
          <div className="col-2 m-0 p-0">
            <Image src={`gallery-img-1.jpg`}></Image>
            <Image src={`gallery-img-2.jpg`}></Image>
            <Image src={`gallery-img-3.jpg`}></Image>
            <Image src={`gallery-img-4.jpg`}></Image>
            <Image src={`gallery-img-5.jpg`}></Image>
            <Image src={`gallery-img-6.jpg`}></Image>
            <Image src={`gallery-img-7.jpg`}></Image>
            {/* <Image src={`gallery-img-8.jpg`}></Image>s */}
            <Image src={`gallery-img-9.jpg`}></Image>
            <Image src={`gallery-img-10.jpg`}></Image>
            <Image src={`gallery-img-11.jpg`}></Image>
            <Image src={`gallery-img-13.jpg`}></Image>
            <Image src={`gallery-img-14.jpg`}></Image>
            <Image src={`gallery-img-15.jpg`}></Image>
            <Image src={`gallery-img-16.jpg`}></Image>
            <Image src={`gallery-img-29.jpg`}></Image>
            <Image src={`gallery-img-30.jpg`}></Image>
            <Image src={`gallery-img-27.jpg`}></Image>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
